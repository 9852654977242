*:after,
*:before,
p a,
a,
.it-btn,
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span,
.it-project figure img,
.it-project:hover figcaption,
.it-plan h4,
.it-plan,
.it-planprice,
.team-memberpic img,
.team-memberpic figcaption,
.postimg figcaption, .description, .pricing-row .pricing-item, .team-memberinfo, .theme-btn, .gallery-filter li, #contactForm input, #contactForm textarea, .theme-btn-white, .navbar-nav li a, #back-to-top, .client-logo img, .icon-view code, .box-shadow-hover, ul#portfolio-filter li{
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}
.owl-origin {
	-webkit-perspective: 1200px;
	-webkit-perspective-origin-x : 50%;
	-webkit-perspective-origin-y : 50%;
	-moz-perspective : 1200px;
	-moz-perspective-origin-x : 50%;
	-moz-perspective-origin-y : 50%;
	perspective : 1200px;
}
/* fade */
.owl-fade-out {
	z-index: 10;
	-webkit-animation: fadeOut .7s both ease;
	-moz-animation: fadeOut .7s both ease;
	animation: fadeOut .7s both ease;
}
.owl-fade-in {
	-webkit-animation: fadeIn .7s both ease;
	-moz-animation: fadeIn .7s both ease;
	animation: fadeIn .7s both ease;
}
/* backSlide */
.owl-backSlide-out {
	-webkit-animation: backSlideOut 1s both ease;
	-moz-animation: backSlideOut 1s both ease;
	animation: backSlideOut 1s both ease;
}
.owl-backSlide-in {
	-webkit-animation: backSlideIn 1s both ease;
	-moz-animation: backSlideIn 1s both ease;
	animation: backSlideIn 1s both ease;
}
/* goDown */
.owl-goDown-out {
	-webkit-animation: scaleToFade .7s ease both;
	-moz-animation: scaleToFade .7s ease both;
	animation: scaleToFade .7s ease both;
}
.owl-goDown-in {
	-webkit-animation: goDown .6s ease both;
	-moz-animation: goDown .6s ease both;
	animation: goDown .6s ease both;
}
/* scaleUp */
.owl-fadeUp-in {
	-webkit-animation: scaleUpFrom .5s ease both;
	-moz-animation: scaleUpFrom .5s ease both;
	animation: scaleUpFrom .5s ease both;
}
.owl-fadeUp-out {
	-webkit-animation: scaleUpTo .5s ease both;
	-moz-animation: scaleUpTo .5s ease both;
	animation: scaleUpTo .5s ease both;
}
/* Keyframes */
/*empty*/
@-webkit-keyframes empty {0% {opacity: 1}}
@-moz-keyframes empty {0% {opacity: 1}}
@keyframes empty {0% {opacity: 1}}
@-webkit-keyframes fadeIn {
	0% { opacity:0; }
	100% { opacity:1; }
}
@-moz-keyframes fadeIn {
	0% { opacity:0; }
	100% { opacity:1; }
}
@keyframes fadeIn {
	0% { opacity:0; }
	100% { opacity:1; }
}
@-webkit-keyframes fadeOut {
	0% { opacity:1; }
	100% { opacity:0; }
}
@-moz-keyframes fadeOut {
	0% { opacity:1; }
	100% { opacity:0; }
}
@keyframes fadeOut {
	0% { opacity:1; }
	100% { opacity:0; }
}
@-webkit-keyframes backSlideOut {
	25% { opacity: .5; -webkit-transform: translateZ(-500px); }
	75% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
	100% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
}
@-moz-keyframes backSlideOut {
	25% { opacity: .5; -moz-transform: translateZ(-500px); }
	75% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
	100% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
}
@keyframes backSlideOut {
	25% { opacity: .5; transform: translateZ(-500px); }
	75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
	100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
}
@-webkit-keyframes backSlideIn {
	0%, 25% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(200%); }
	75% { opacity: .5; -webkit-transform: translateZ(-500px); }
	100% { opacity: 1; -webkit-transform: translateZ(0) translateX(0); }
}
@-moz-keyframes backSlideIn {
	0%, 25% { opacity: .5; -moz-transform: translateZ(-500px) translateX(200%); }
	75% { opacity: .5; -moz-transform: translateZ(-500px); }
	100% { opacity: 1; -moz-transform: translateZ(0) translateX(0); }
}
@keyframes backSlideIn {
	0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
	75% { opacity: .5; transform: translateZ(-500px); }
	100% { opacity: 1; transform: translateZ(0) translateX(0); }
}
@-webkit-keyframes scaleToFade {to { opacity: 0; -webkit-transform: scale(.8); }}
@-moz-keyframes scaleToFade {to { opacity: 0; -moz-transform: scale(.8); }}
@keyframes scaleToFade {to { opacity: 0; transform: scale(.8); }}
@-webkit-keyframes goDown {from { -webkit-transform: translateY(-100%); }}
@-moz-keyframes goDown {from { -moz-transform: translateY(-100%); }}
@keyframes goDown {from { transform: translateY(-100%); }}
@-webkit-keyframes scaleUpFrom {from { opacity: 0; -webkit-transform: scale(1.5); }}
@-moz-keyframes scaleUpFrom {from { opacity: 0; -moz-transform: scale(1.5); }}
@keyframes scaleUpFrom {from { opacity: 0; transform: scale(1.5); }}
@-webkit-keyframes scaleUpTo {to { opacity: 0; -webkit-transform: scale(1.5); }}
@-moz-keyframes scaleUpTo {to { opacity: 0; -moz-transform: scale(1.5); }}
@keyframes scaleUpTo {to { opacity: 0; transform: scale(1.5); }}

@-webkit-keyframes scaleIn {
  from {
    -webkit-transform: translateY(-50%) scale(0);
  }
  to {
    -webkit-transform: translateY(-50%) scale(1);
  }
}
@-moz-keyframes scaleIn {
  from {
    -moz-transform: translateY(-50%) scale(0);
  }
  to {
    -moz-transform: translateY(-50%) scale(1);
  }
}
@keyframes scaleIn {
  from {
    -webkit-transform: translateY(-50%) scale(0);
    -moz-transform: translateY(-50%) scale(0);
    -ms-transform: translateY(-50%) scale(0);
    -o-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
  }
  to {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
  }
}
@-webkit-keyframes slideIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(-25px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes slideIn {
  from {
    opacity: 0;
    -moz-transform: translateX(-25px);
  }
  to {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@keyframes slideIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(-25px);
    -moz-transform: translateX(-25px);
    -ms-transform: translateX(-25px);
    -o-transform: translateX(-25px);
    transform: translateX(-25px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

#loader-overlay {
    width: 100%;
    height: 100vh;
    background: #f1f1f1;
    position: fixed;
    z-index: 999999;
}
.loader-wrapper {
  width: 148px;
  height: 100px;
  position: absolute;
  margin: -50px 0 0 -74px;
  left: 50%;
  top: 50%;
}


.scoda-pulse {
  width: 40px;
  height: 40px;
  margin: 40px auto;
  background-color: #d42e22;
  border-radius: 100%;
  -webkit-animation: scoda-pulseScaleOut 1s infinite ease-in-out;
          animation: scoda-pulseScaleOut 1s infinite ease-in-out; }

@-webkit-keyframes scoda-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }

@keyframes scoda-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }


/* Gradient Animation */
@-webkit-keyframes AnimationName {
    0%{background-position:86% 0%}
    50%{background-position:15% 100%}
    100%{background-position:86% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:86% 0%}
    50%{background-position:15% 100%}
    100%{background-position:86% 0%}
}
@-o-keyframes AnimationName {
    0%{background-position:86% 0%}
    50%{background-position:15% 100%}
    100%{background-position:86% 0%}
}
@keyframes AnimationName {
    0%{background-position:86% 0%}
    50%{background-position:15% 100%}
    100%{background-position:86% 0%}
}



.fade-icon {
	background: rgba(255,255,255,0.1);
}

.feature-box .fade-icon:after {
	top: -7px;
    left: -7px;
    padding: 7px;
    box-shadow: 0 0 0 4px #d42e22;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    transition: transform 0.2s, opacity 0.2s;
    transform: scale(.8);
    opacity: 0;
}

.feature-box .fade-icon-yellow:after {
    box-shadow: 0 0 0 4px #F7971E;
}

.feature-box .fade-icon-white:after {
    box-shadow: 0 0 0 4px #fff;
}

.feature-box .fade-icon-dark:after {
    box-shadow: 0 0 0 4px #212121;
}

.feature-box .fade-icon-pink:after {
    box-shadow: 0 0 0 4px #c31432;
}

.feature-box .fade-icon-blue:after {
    box-shadow: 0 0 0 4px #0072ff;
}

.feature-box .fade-icon-green:after {
    box-shadow: 0 0 0 4px #1D9A6C;
}



.fade-icon:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
	border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.feature-box .fade-icon:hover:after {
	-webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

@-webkit-keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
		-webkit-transform: scale(1.5);
		opacity: 0;
	}
}
@-moz-keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
		-moz-transform: scale(1.5);
		opacity: 0;
	}
}
@keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
		transform: scale(1.5);
		opacity: 0;
	}
}

@-webkit-keyframes fadeTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeTop {
  -webkit-animation-name: fadeTop;
  animation-name: fadeTop;
}

@-webkit-keyframes fadeLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeLeft {
  -webkit-animation-name: fadeLeft;
  animation-name: fadeLeft;
}

@-webkit-keyframes fadeRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeRight {
  -webkit-animation-name: fadeRight;
  animation-name: fadeRight;
}
